import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
export class ApplicationForm extends Component {   

    static displayName = ApplicationForm.name;
   


    render() {
        

        return (
            <div>
                <iframe src="https://docs.google.com/forms/d/1WucSnvGCmSDRbv_N5FVaTnk4RyIHWYY_mRpzTCRUaA8/viewform?embedded=true" width="1000" height="2400" frameBorder="0"></iframe>                
            </div>
        );
    }


}